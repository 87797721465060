import { createI18n } from "vue-i18n";

let config: any = null;

export const makeI18nConfig = (messages?: any) => {
    if (!config && messages) {
        config = {
            messages,
            fallbackLocale: "en",
            locale: "de",
            injectGlobal: true,
            legacy: false,
        };
    }

    return config;
};

export const makeI18n = () => {
    const i18nConfig = makeI18nConfig();

    return createI18n(i18nConfig);
};
