import "./bootstrap";
import "../css/app.css";
import "element-plus/dist/index.css";

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import ElementPlus from "element-plus";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m";

import CodeDiff from "v-code-diff";
import nginx from "highlight.js/lib/languages/nginx";
import { makeI18n, makeI18nConfig } from "@/i18n.ts";

CodeDiff.hljs.registerLanguage("nginx", nginx);

const appName = window.document.getElementsByTagName("title")[0]?.innerText || "Laravel";

createInertiaApp({
    progress: { color: "#4B5563" },
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob("./Pages/**/*.vue")),
    setup({ el, App, props, plugin }) {
        const i18nConfig = makeI18nConfig(props.initialPage.props.translations);
        const i18n = makeI18n();

        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(i18n)
            .use(ZiggyVue, Ziggy)
            .use(ElementPlus)
            .use(CodeDiff)
            .mixin({ methods: { route } })
            .mount(el);
    },
});
